import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import resumepdf from '../../resume.pdf';
import resumesecpdf from '../../resume-sec.pdf';
import { HashLink as Link } from 'react-router-hash-link';

function TopNav(props) {
    return (
        <Navbar id="header-nav" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand>Alexander Yeara</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto personal-links">
                <Nav.Item>
                    <Nav.Link
                        href="https://linkedin.com/in/alexanderyeara"
                        className = "nav-link nav-link"
                        target='_blank'
                    >
                        LinkedIn
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        href="https://github.com/axyeara"
                        className = "nav-link nav-link"
                        target='_blank'
                    >
                        GitHub
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        href={resumepdf}
                        className = "nav-link nav-link"
                        target='_blank'
                    >
                        Resume (Engineering)
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        href={resumesecpdf}
                        className = "nav-link nav-link"
                        target='_blank'
                    >
                        Resume (AppSec)
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Nav className="ml-auto">
                <Link className="nav-link" smooth to="#about">
                    About
                </Link>
                <Link className="nav-link" smooth to="#experience"> 
                    Experience
                </Link>
                <Link className="nav-link" smooth to="#portfolio">
                    Portfolio
                </Link>
                <Link className="nav-link" smooth to="#certifications">
                    Certifications
                </Link>
                <Link className="nav-link" smooth to="#skills">
                    Skills
                </Link>
                <Link className="nav-link" smooth to="#contact">
                    Contact
                </Link>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default TopNav;