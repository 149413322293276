import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import audienceviewlogo from '../../audienceviewlogo.png';
import uoftlogo from '../../uoftlogo.png';
import strataprimelogo from '../../strataprimelogo.png';
import { Image } from 'react-bootstrap';
import microsoftlogo from '../../microsoftlogo.png';

function ExperienceTimeLine(props) {

    const paperstyle={
        padding: '8px 1px',
        textAlign:'center'
    }

    return (
        <Timeline className="p-0">
            <TimelineItem className="microsoftsection">
                <TimelineSeparator>
                    <TimelineDot className="bg-primary">
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <div className="timeline-jobdesc">
                        <Image src={microsoftlogo} id="microsoftlogo" alt="Microsoft" className='companyimg'/>
                        <h3>Software Engineer</h3>
                        <small><strong>Microsoft, August 2021 to Present</strong></small>
                        <small><strong>Product: Microsoft Whiteboard</strong></small>
                        <ul>
                            <li>Engaged with stakeholders to implement core features for Surface Hub and Teams Rooms devices; utilized React,
TypeScript, and C#</li>
                            <li>Improved security posture by developing STRIDE threat models, performing security reviews, and mitigating
vulnerabilities; Conducted a presentation to approximately 40 colleagues, receiving excellent reviews</li>
                            <li>Implemented bug fixes, hotfixes, and unit tests for user interfaces and API service functionalities</li>
                            <li> As an on-call engineer, engaged with clients and other teams to investigate and mitigate high-priority incidents</li>
                            <li>Improved incident response by creating troubleshooting guides for critical failures and implementing telemetry
dashboards for monitoring</li>
                        </ul>
                    </div>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem className="audienceviewsection">
                <TimelineSeparator>
                    <TimelineDot className="bg-primary">
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <div className="timeline-jobdesc">
                        <Image src={audienceviewlogo} id="audienceviewlogo" alt="AudienceView" className='companyimg'/>
                        <h3>Front-End Software Engineer</h3>
                        <small><strong>AudienceView, Nov 2019 to August 2021</strong></small>
                        <small><strong>Product: AudienceView Unlimited</strong></small>
                        <ul>
                            <li>Developed event management UI using JavaScript and Bootstrap to aid ticket sales across 1000+ venues per year</li>
                            <li>Co-led feature developments for WCAG web accessibility guidelines, increasing user accessibility by 40%</li>
                            <li>Created factory patterns for tables and modal windows that provide responsive, accessible and cross-browser
capabilities; increased productivity by approximately 20%</li>
<li>Led application security improvements by developing scalable solutions against OWASP Top 10 vulnerabilities,
optimizing ZAP scans, and improving secure coding standards; mitigated roughly 60% of known security risks</li>
                        </ul>
                    </div>

                </TimelineContent>
            </TimelineItem>
            <TimelineItem className="uoftsection">
                <TimelineSeparator>
                    <TimelineDot className="bg-primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <div className="timeline-jobdesc">
                        <Image src={uoftlogo} id="uoftlogo" alt="University of Toronto" className='companyimg'/>
                        <h3>Undergraduate Teaching Assistant</h3>
                        <small><strong>University of Toronto, January 2019 to January 2020</strong></small>
                        <ul>
                            <li>Taught Introduction to Programming (CSC108), Introduction to Computer Science (CSC148), and Theory of Computation (CSC236) to approximately 40 students in tutorial sessions</li>
                            <li> Produced questions used in tutorial sessions, assignments, and examinations</li>
                            <li>Helped students succeed in their courses by teaching learning techniques and problem-solving strategies</li>
                        </ul>
                    </div>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem className="strataprimesection">
                <TimelineSeparator>
                    <TimelineDot className="bg-primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <div className="timeline-jobdesc">
                        <Image src={strataprimelogo} id="strataprimelogo" alt="StrataPrime" className='companyimg'/>
                        <h3>Full-Stack Software Developer</h3>
                        <small><strong>StrataPrime, May 2018 to August 2018</strong></small>
                        <small><strong>Product: Availability Tracker</strong></small>
                        <ul>
                            <li>Solely developed a web application using Google Apps Script that allows managers to keep track and be in control of employee availability within the G Suite environment</li>
                            <li>Designed Cloud SQL database, user interfaces, and general operations</li>
                        </ul>
                    </div>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem className="uoftsection">
                <TimelineSeparator>
                    <TimelineDot className="bg-primary"/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <div className="timeline-jobdesc">
                        <Image src={uoftlogo} id="uoftlogo" alt="University of Toronto" className='companyimg'/>
                        <h3>Undergraduate Research Chemist</h3>
                        <p><small><strong>University of Toronto, May 2016 to October 2016</strong></small></p>
                        <ul>
                            <li>As a Research Opportunity Program student, I worked with a supervisor to design and synthesize a library of derivative molecules with desirable biological efficacy against ROS1-fusion related disease</li>
                        </ul>
                    </div>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot className="bg-primary"/>
                </TimelineSeparator>
            </TimelineItem>
        </Timeline>
    )
}

export default ExperienceTimeLine;