import aleximage from './aleximage.jpeg';
import cbbh from './cbbh.png'
import chessimage from './chess.png';
import videogame from './videogame.png';
import pingpong from './pingpong.png';
import clack from './clack.png';
import paintprogram from './paintprogram.png';
import shootergame from './shootergame.jpg';
import warehousegame from './warehousegame.png';
import personalsite from './personalsite.png';
import speedgame from './speedgame.PNG';
import speedregister from './speedregister.PNG';
import speedsearching from './speedsearching.PNG';
import speedplaycard from './speedplaycard.png';
import jugpuzzle from './jugpuzzle.png';
import burpcert from './burpcert.png';
import './App.css';
import TopNav from './components/TopNav/TopNav.js';
import { Image, Container, Row, Col, Table, Carousel } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai';
import { Paper } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { MdArrowUpward } from 'react-icons/md';
import { HashLink as Link } from 'react-router-hash-link';
import ExperienceTimeLine from './components/ExperienceTimeLine/ExperienceTimeLine';

function App() {
  return (
    <Router>
      <div className="App">
        <header id="top">
          <TopNav />
          
          <section id="header-body" className="container">
            <Image src={aleximage} id="aleximage" alt="Image of Alexander Yeara" roundedCircle />
            <p id="header-p">
              Hi! I'm Alexander Yeara, a software and application security engineer based in Oakville, Canada
              
            </p>
          </section>
        </header>
        <Container id="about" className="content-section">
          <h1>About Me</h1>
          <p>
          I am a software developer and application security engineer at Microsoft who enjoys learning and tackling diverse challenges. This interest led me to explore software development as a means to provide robust, scalable, and accessible solutions across different domains. Additionally, I've become increasingly interested in security and have served as a security champion on multiple occasions. Currently, I'm a member of Microsoft's Whiteboard security team. In this role, I improve the application's security posture through threat modeling, security reviews, and risk mitigation. As a hobby, I dedicate my time to learning more about penetration testing and security engineering, while also acquiring technical certifications.
          </p>
          <p>
          I earned an Honours Bachelor of Science degree with a focus on chemistry, computer science, and mathematics from the University of Toronto. As a student, I engaged in various activities such as cancer research as a medicinal chemist, managed the campus chess club as president, and assisted in teaching three different computer science courses.
          </p>
          <p>
          I'm an avid online bullet chess player (2000 - 2200 rating) on Lichess, Chess.com, and Chess24 websites. I also enjoy reading, playing video games, board games, ping-pong, writing poetry, and playing basketball.
          </p>
          <div class="hobby-icon-container flex">
            <Image src={chessimage} className="hobby-images" alt="Chess"  />
            <Image src={videogame} className="hobby-images" alt="Video Games"  />
            <Image src={pingpong} className="hobby-images" alt="Ping Pong"  />
          </div>
        </Container>
        <Container id="experience" className="content-section">
          <h1>Experience</h1>
          <ExperienceTimeLine />
        </Container>
        <Container id="portfolio" className="content-section">
          <h1>Projects</h1>
<Row className="project">
<Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>React Speed</h3>
                <ul>
                  <li>
                    Solely developing an online multiplayer card game with authentication, matchmaking and real-time gameplay
                  </li>
                  <li>
                    Features: game settings, quickplay, player lobby, user profile, leaderboard, registration, and password change/reset
                  </li>
                  <li>
                    <strong>Utilized: </strong>React, MongoDB, Node.js, Express, and Socket.IO
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
            <Carousel>
            <Carousel.Item>
    <img
      className="d-block w-100"
      src={speedplaycard}
      alt="First slide, speed card game card movement"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={speedgame}
      alt="Second slide, speed card game"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={speedsearching}
      alt="Third slide, speed card game player search"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={speedregister}
      alt="Fourth slide, speed card game registration form"
    />
  </Carousel.Item>
</Carousel>
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>CS50 Clack</h3>
                <ul>
                  <li>
                    Designed and developed a lightweight single-page messaging website. This was done as a practice assignment for Harvard’s CS50 course
                  </li>
                  <li>
                    Incorporated client-based non-persistent storage for usernames and server-side non-persistent storage for channels and messages
                  </li>
                  <li>
                    <strong>Utilized: </strong>Python, JavaScript, HTML, CSS, AJAX, Socket.io, Flask, History API, LocalStorage API
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 1}}>
              <Image src={clack} className="portfolio-image" alt="CS50 Clack" />
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>Graphics Editor</h3>
                <ul>
                  <li>
                    Designed and developed a graphics editor desktop program as a scrum master and developer with a team of students
                  </li>
                  <li>
                    Incorporated the Observer, Factory, Command, and Strategy design patterns for the creation and manipulation of different shapes
                  </li>
                  <li>
                    <strong>Utilized: </strong>Java, Swing, Model-View-Controller (MVC), Agile/Waterfall methodologies, Version-Control Software (i.e. Git), design patterns and object-oriented programming
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={paintprogram} className="portfolio-image" alt="Graphics Editor" />
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>Shooter Game</h3>
                <ul>
                  <li>
                    Contributed to the development of a multiplayer browser game by designing architecture,  implementing gameplay physics and general user interface
                  </li>
                  <li>
                    Used object-oriented programming and a state design pattern for the transition of game flows
                  </li>
                  <li>
                    <strong>Utilized: </strong>JavaScript, HTML, CSS, Golang, SQL
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={shootergame} className="portfolio-image" alt="Shooter Game" />
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>Warehouse Game</h3>
                <ul>
                  <li>
                    Developed a singleplayer game with Python as one of my first school assignments. The purpose is to
                    kill all the monsters in the game by surrounding them with boxes
                  </li>
                  <li>
                    Used object-oriented programming for players, monsters and boxes
                  </li>
                  <li>
                    <strong>Utilized: </strong> Python
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={warehousegame} className="portfolio-image" alt="Warehouse Game" />
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>Jug Puzzle Game</h3>
                <ul>
                  <li>
                    Developed a singleplayer jug puzzle game using object-oriented programming and Model-View-Controller
                    (MVC) as a second-year student
                  </li>
                  <li>
                    <strong>Utilized: </strong> Java, Swing
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={jugpuzzle} className="portfolio-image" alt="Jug Puzzle" />
            </Col>
          </Row>
          <Row className="project">
          <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="project-desc">
                <h3>This Site!</h3>
                <ul>
                  <li>
                    Developed a small portfolio website to showcase projects and interests.
                  </li>
                  <li>
                    <strong>Utilized: </strong> React
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={personalsite} className="portfolio-image" alt="Personal Site" />
            </Col>
          </Row>
        </Container>
        <Container id="certifications" className="content-section">
          <h1>Certifications</h1>
          <Row className="certification">
            <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="certification-desc">
                <h3>Certified Bug Bounty Hunter (Hack The Box)</h3>
                <small><a href="https://www.credly.com/badges/fa712a96-cff9-42e4-a0ae-ce14047ec946/public_url" target="_blank" rel="noreferrer">Digital credential</a></small>
                <ul>
                  <li>
                  "HTB CBBH holders possess technical competency in the bug bounty hunting and web penetration testing domains at an intermediate level. They will be able to spot security issues and identify avenues of exploitation that may not be immediately apparent from searching for CVEs or known exploit PoCs. They can also think outside the box, chain multiple vulnerabilities to showcase maximum impact, and actionably help developers remediate vulnerabilities through commercial-grade bug reports."
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={cbbh} className="certification-image" alt="Certified bug bounty hunter certification" />
            </Col>
          </Row>
          <Row className="certification">
            <Col xs={{span: 12, order: 1}} md={{span:6, order: 1}}>
              <div className="certification-desc">
                <h3>Burp Suite Certified Practitioner (PortSwigger)</h3>
                <small><a href="https://portswigger.net/web-security/e/c/ba029f4212ca75ac" target="_blank" rel="noreferrer">Digital credential</a></small>
                <p>"This certification, created by PortSwigger's Web Security Academy, demonstrates that I have the ability to:</p>
                <ul>
                  <li>
                    Detect and prove the full business impact of a wide range of common web vulnerabilities.
                  </li>
                  <li>
                    Adapt attack methods to bypass broken defences, using knowledge of fundamental web technologies.
                  </li>
                  <li>
                  Quickly identify weak points within an attack surface, and perform out-of-band attacks to attack them."
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={{span: 12, order: 2}} md={{span:6, order: 2}}>
              <Image src={burpcert} className="certification-image" alt="Burp Suite Certified Practitioner certification" />
            </Col>
          </Row>
        </Container>
        <Container id="skills" className="content-section">
          <h1>Skills</h1>
          <Table borderless responsive="sm">
            <tbody>
              <tr>
                <td>
                  Java
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Python
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  JavaScript (ES6)
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  SQL
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  HTML
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  CSS
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  ASP Classic
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Sass
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  MongoDB
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  React
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Jest
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  C#
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Bootstrap
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  jQuery
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Git
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Node.js
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  Bash
                </td>
                <td>
                  <Paper className="comfortable">
                    Comfortable
                  </Paper>
                </td>
              </tr>
              <tr>
                <td>
                  C
                </td>
                <td>
                  <Paper className="some-exp">
                    Some exposure
                  </Paper>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
        <Container id="contact" className="content-section">
        <h1>Contact</h1>
        <div>
          <AiOutlineMail /> <p>alexanderyeara2@gmail.com</p>
        </div>
        <p>Feel free to also send a connection request on LinkedIn!</p>
      </Container>
        <div>
          <Link smooth id="backtotop" className="nav-link" to="#top">
            <MdArrowUpward /> <span>Back to top</span>
          </Link>
        </div>
      </div>
    </Router>
  );
}

export default App;
